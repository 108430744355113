//====================== IMPORTANT NOTE: Do not change import order! =====================//
// Reset styling.
@import 'reset';
@import 'lib';

html,
body,
#root {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #323232;
  transition: all 0.3s ease-in-out;
  &:visited {
    color: #323232;
  }
  &:hover {
    color: #000;
  }
}

.bottom-up {
  -webkit-animation-name: bottomUp;
  animation-name: bottomUp;
  animation-duration: 0.4s;
}

.right-to-left {
  -webkit-animation-name: rightToLeft;
  animation-name: rightToLeft;
  animation-duration: 0.4s;
}

.ProductPrices {
  color: #000;

  h4 {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

  &.sale {
    h4 {
      margin: 0;
      &.regular {
        text-decoration-line: line-through;
        text-decoration-color: red;
        font-weight: normal;
        margin-right: 8px;
      }

      &.sale {
        color: red;
      }
    }
  }
}
