.HTMLContent {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 20px;
  }
  ol {
    padding-left: 18px;
    list-style-type: decimal;
  }
  ul {
    padding-left: 18px;
    list-style: disc;
  }
}
