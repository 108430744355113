.plyr {
  font-family: Roboto, sans-serif !important;
}

.plyr--full-ui input[type="range"] {
  color: #4fb848;
  font-family: "Roboto", sans-serif;
}

.plyr__control--overlaid {
  background: rgba(79, 184, 72, 0.8);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #4fb848;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(79, 184, 72, 0.5);
}

.plyr__menu__container
  .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
  background: #4fb848;
}
