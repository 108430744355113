//Override bootstrap variables here, and use it to declare other variables.
@import "_custom_variables";

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin backgroundAutofill($color: #ffffff) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus &:-webkit-autofill,
  &:-webkit-autofill:hover &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $color inset !important;
  }
}

@mixin customScrollbar($width: 6px, $color: $black) {
  //&::-webkit-scrollbar-track {
  //}

  &::-webkit-scrollbar {
    width: $width;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color;
  }
}

@keyframes bottomUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rightToLeft {
  0% {
    opacity: 0;
    transform: translateX(15px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
